<template>
	<PopupLayer>
		<template
			slot="body"
		>
			<div class="flex-column full-height bg-white position-relative">

				<div class="justify-space-between pa-10 under-line">

					<h6>계좌 인증</h6>
					<button
						@click="$emit('cancel')"
					><v-icon>mdi mdi-close</v-icon></button>
				</div>
				<div class="full-height overflow-y-auto  pa-10">
					<div class="flex-row justify-space-between items-center">
						<div class="flex-1 font-weight-bold">은행</div>
						<div class="flex-2 ">
							<select
								v-model="item_withdrawal.bank_code"
								class="input-box"
								style="color: black;"
							>
								<option value="">은행을 선택하세요</option>
								<option
									v-for="(item, b_index) in bank_list"
									:key="'bank_' + b_index"
									:value="item.bankId"
								>{{ item.bankName }}</option>
							</select>
						</div>
					</div>
					<div class="mt-10 flex-row justify-space-between items-center">
						<div class="flex-1 font-weight-bold">출금 계좌</div>
						<div class="flex-2"><input v-model="item_withdrawal.bank_account" class="input-box" placeholder="계좌번호를 입력하세요"/></div>
					</div>
					<div class="mt-10 flex-row justify-space-between items-center">
						<div class="flex-1 font-weight-bold">이름</div>
						<div class="flex-2"><div class="input-box">{{ user.member_name ? user.member_name : '본인인증 후 조회하세요' }} </div></div>
					</div>
					<div class="mt-10 flex-row justify-space-between items-center">
						<div class="flex-1 font-weight-bold">예금주</div>
						<div class="flex-2"><div class="input-box">{{ item_withdrawal.bank_holder }} </div></div>
					</div>
				</div>

				<div class="mt-10 "><button class="btn btn-primary" @click="postHolder">예금주 조회</button></div>
			</div>
		</template>
	</PopupLayer>
</template>
<script>
import PopupLayer from "../Layout/PopupLayer";
export default {
	name: 'Bank'
	,
	components: {PopupLayer},
	props: ['user', 'Axios']
	, data: function(){
		return {
			program: {
				name: '지갑 출금'
				, top: false
				, title: true
				, bottom: false
			}
			, items_withdrawal: []
			, shop_balance: ''
			, shop_uid: this.$route.params.shop_uid
			, items_account: []
			, shop_info: {}
			, max: 5
			, item_last: {}
			, items_bank: []
			, item_withdrawal: {
				bank_code: ''
				, bank_holder: ''
			}
		}
	}
	, computed: {
		bank_list: function(){
			return this.items_bank.filter( (item) => {
				return item
			})
		}
	}
	, methods: {

		setAccount: function(item_withdrawal){
			console.log(item_withdrawal)
			this.items_account.filter( (item) => {
				if(item.uid == item_withdrawal.account_uid){
					item_withdrawal.bank_code = item.bank_code
					item_withdrawal.bank_account = item.bank_account
					item_withdrawal.bank_holder = item.bank_holder
				}
			})
		}
		, getData: async function(){
			try{
				this.$bus.$emit('on', true)
				let result = await this.Axios({
					method: 'get'
					, url: 'main/getBankList'
				})
				if(result.success){
					this.items_bank = result.data.data
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, onPin: function(){
			this.$bus.$off('pinCallback')

			this.$bus.$emit('onPin', {
				type: 'check'
			})

			this.$bus.$on('pinCallback', (call) => {
				console.log('multiwithdrawal pinCallback', call)
				this.postMultiWithdrawal(call)
			})
		}
		, postMultiWithdrawal: async function(){
			this.$bus.$emit('on', true)

			try {

				const result = await this.Axios({
					method: 'post'
					, url: 'user/postMultiWithdrawal'
					, data: {
						my_shop_uid: this.shop_uid
						, items: JSON.stringify(this.items_withdrawal)
					}
				})

				if (result.success) {
					this.$bus.$emit('to', { name: 'WalletList'})
				} else {
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postHolder: async function(){
			try{
				this.$bus.$emit('on', true)
				let result = await this.Axios({
					method: 'post'
					, url: 'main/postHolder'
					, data: {
						bank_code: this.item_withdrawal.bank_code
						, bank_account: this.item_withdrawal.bank_account
						, bank_holder: this.user.member_name
					}
				})
				if(result.success){
					if(this.user.member_name == result.data.realBankOwnerName){
						this.is_holder = true
						this.item_withdrawal.bank_holder = result.data.realBankOwnerName
						this.$emit('click', this.item_withdrawal)
					}else{
						throw "본인인증정보와 예금주정보가 일치하지 않습니다."
					}
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, appendItem: function(){
			try {

				if(this.items_withdrawal.length < this.max){

					this.items_withdrawal.push({
						shop_uid: this.shop_uid
						, amount: ''
						, bank_code: ''
						, bank_account: ''
						, bank_holder: ''
						, is_on_sms_confirm: false
						, is_sms_confirm: false
						, depositor: ''
						, account_uid: ''
						, is_holder: false
						, item_holder: {
							realBankOwnerName: ''
						}
					})

					if(this.invalid_balance < 0){
						this.items_withdrawal.pop()
						throw "출금가능금액을 확인하세요"
					}

				}else{
					throw `일괄이체는 최대 ${this.max}건까지 가능합니다`
				}
			}catch (e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, removeItem: function(index){
			if(index > 0){
				this.items_withdrawal.splice(index, 1)
			}
		}
		, setLast: function(item){
			this.item_last = item
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getData()
		this.appendItem()
	}
	, watch: {
		total_amount: {
			handler: function(call){
				if(call > Number(this.invalid_balance) || Number(this.invalid_balance) < 0){
					this.$set(this.item_last, 'amount', '')
					this.$bus.$emit('notify', { type: 'error', message: '출금가능금액을 확인하세요'})
				}
			}
		}
	}
}
</script>